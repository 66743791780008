@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';

.ctf-feed-1 {
	position: relative;
	padding: 3rem;
	border: solid 2px colors.$blue;
	border-radius: 20px;
}

.ctf-carousel__wrapper {
	min-height: 100%;
}

#ctf.ctf-feed-1 {
	a {
		color: colors.$blue;
	}

	.ctf-corner-logo {
		position: absolute;
		top: unset;
		bottom: 3rem;
		left: 3rem;

		svg {
			min-width: 6rem;
			min-height: 5rem;
		}
	}

	.ctf-item {
		padding: 0;
	}

	.ctf-author-box-link {
		font-weight: 900;
		color: colors.$grey-400;

		@extend %font-sm;
	}

	.ctf-tweet-content {
		margin-top: 5rem;
		color: colors.$grey-400;
	}

	.ctf-tweet-text {
		word-break: break-word;
	}

	.swiper-pagination {
		position: absolute;
		top: 0;
		right: 0;
		left: unset;
		width: fit-content;
	}
}
